import React from 'react';
import { Layout, PrimaryHeader, OffWhiteContent } from './Layout';
import { ProcedureHtml } from './ProcedureLayout';
import { useTranslation } from 'react-i18next';

export const StaticPage = ({ html, title, navigate }) => {
  const { t } = useTranslation();
  return (
    <Layout
      header={
        <PrimaryHeader
          title={t(title)}
          onClickBack={() => window.history.back()}
          onSearchClick={() => navigate('/search')}
        />
      }
      content={
        <OffWhiteContent>
          {!html && <h1>Not found</h1>}
          {!!html && (
            <ProcedureHtml dangerouslySetInnerHTML={{ __html: html }} />
          )}
        </OffWhiteContent>
      }
    />
  );
};
