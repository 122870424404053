import React from 'react';
import { createPortal } from 'react-dom';
import styled, { createGlobalStyle, withTheme } from 'styled-components';
import { animated, useSpring } from 'react-spring';

const Global = createGlobalStyle`body {
  overflow: hidden;
}`;
const ModalWrapperAnimated = styled(animated.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 10;
`;
const PromptRelativeWrapper = styled.div`
  position: relative;
`;
const PromptWrapperAnimated = styled(animated.div)`
  position: absolute;
  opacity: 0;
  z-index: 11;
  width: 100%;
`;
const PromptWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-around;
`;
const PromptContent = styled.div`
  background: ${({ theme }) => theme.offwhite};
`;
const PromptTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: ${({ theme }) => theme.fonts.size.medium};
  color: ${({ theme }) => theme.primary};
  padding: 15px 40px;
  text-align: center;
  border-bottom: 2px solid ${({ theme }) => theme.border};
`;

export function Prompt({ children, title }) {
  const props = useSpring({
    to: async (next, cancel) => {
      await next({ opacity: 1, top: '25%' });
      cancel();
    },
    from: { opacity: 0, top: '-10%' },
  });
  return (
    <PromptWrapperAnimated style={props} onClick={(e) => e.stopPropagation()}>
      <PromptRelativeWrapper>
        <PromptWrapper>
          <PromptContent>
            <PromptTitle>{title}</PromptTitle>
            {children}
          </PromptContent>
        </PromptWrapper>
      </PromptRelativeWrapper>
    </PromptWrapperAnimated>
  );
}
const ModalWrapper = withTheme(function ModalWrapper({
  videos,
  show,
  hide,
  theme,
  children,
}) {
  const props = useSpring({
    to: async (next) => {
      await next({ opacity: 1, backgroundColor: theme.animatedDarkGrey(0.4) });
      await next({ opacity: 1, backgroundColor: theme.animatedDarkGrey(0.6) });
    },
    from: { opacity: 0, backgroundColor: theme.animatedDarkGrey(0) },
  });

  return (
    <>
      {show && <Global />}
      {show && (
        <ModalWrapperAnimated style={props} onClick={hide}>
          {children}
        </ModalWrapperAnimated>
      )}
    </>
  );
});

export function Modal({ show, hide, children }) {
  return createPortal(
    <ModalWrapper show={show} hide={hide}>
      {children}
    </ModalWrapper>,
    document.getElementById('modal')
  );
}
