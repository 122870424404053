import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.animatedDarkGrey(0.8)};
  z-index: 20;
  justify-content: center;
  display: flex;
`;
const Content = styled.div`
  align-self: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
`;
const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fonts.size.medium};
  margin-bottom: 20px;
  padding: 0px 40px;
`;
const ButtonText = styled.span`
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fonts.size.medium};
  margin-right: 5px;
`;
const Button = styled.div`
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fonts.size.medium};
  border: 1px solid ${({ theme }) => theme.white};
  padding: 20px;
`;
const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fonts.size.large};
  margin-bottom: 20px;
  padding: 0px 40px;
`;
export function Welcome({ storageKey, title, text, button }) {
  const welcome = useMemo(() => {
    return Boolean(localStorage.getItem(storageKey));
  }, [storageKey]);
  const [show, set] = useState(!welcome);
  if (!show) return null;
  return (
    <Wrapper>
      <Content>
        {title && <Title>{title}</Title>}
        <Text>{text}</Text>
        <Button
          onClick={() => {
            set(false);
            localStorage.setItem(storageKey, true);
          }}
        >
          <ButtonText>{button}</ButtonText>
          <Icon name="chevron" color="white" />
        </Button>
      </Content>
    </Wrapper>
  );
}
