/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import noWifi from './no-wifi.png';
const Image = styled.img`
  width: 15px;
  margin-right: 5px;
`;
const AnimatedOffline = styled(animated.div)`
  width: 100%;
  position: fixed;
  bottom: 0px;
  padding: 15px;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  background-color: ${({ theme }) => theme.red};
  z-index: 20;
  text-align: center;
`;

export function Offline() {
  const { t } = useTranslation();
  const [props, set] = useSpring(() => {
    if (navigator.onLine) return { opacity: 0, bottom: -100 };
    return { opacity: 100, bottom: 0 };
  });
  useEffect(() => {
    function updateOnlineStatus() {
      if (!navigator.onLine) {
        set({ opacity: 100, bottom: 0 });
      } else {
        set({ opacity: 0, bottom: -100 });
      }
    }
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);
  return (
    <AnimatedOffline style={props}>
      <Image src={noWifi} alt-text="" />
      {t('Please check internet connection')}
    </AnimatedOffline>
  );
}
