/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled, { withTheme } from 'styled-components';
const Banner = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: white;
  color: black;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 50;
`;
const Text = styled.div`
  font-size: 16px;
`;

let show = !localStorage.getItem('add-to-homescreen');

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone;
export const AddToHomeScreen = withTheme(function _AddToHomeScreen({
  theme,
  onClick,
}) {
  const [bannerVisible, setVisible] = useState(show);
  const shouldRender = bannerVisible && isIos() && !isInStandaloneMode();

  return (
    <div>
      {shouldRender ? (
        <Banner>
          <Text>
            <a
              onClick={() => {
                setVisible(false);
                onClick();
              }}
            >
              Add to home screen
            </a>
          </Text>
          <FontAwesomeIcon
            className="add-to-home-close-btn"
            icon={faTimes}
            color={theme.icon}
            width="lg"
            onClick={() => {
              setVisible(false);
              show = false;
              localStorage.setItem('add-to-homescreen', true);
            }}
          />
        </Banner>
      ) : null}
    </div>
  );
});
