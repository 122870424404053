import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { useTranslation } from 'react-i18next';
const ProcedureLinksWrapper = styled.div`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 20px;
  background-color: ${({ theme }) => theme.offwhite};
  border-bottom: 3px solid ${({ theme }) => theme.border};
  display: flex;
  justify-content: space-between;
`;
const LinkWrapper = styled.div`
  border-radius: 30px;
  border-color: ${({ theme }) => theme.border};
  border-width: 2px;
  border-style: solid;
  padding: 10px;
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.fonts.default};
  display: flex;
  cursor: pointer;
`;
const IconWrapper = styled.div`
  padding-left: 5px;
`;
function ProcedureLink({ text, onClick, color }) {
  return (
    <LinkWrapper onClick={onClick}>
      {text}
      <IconWrapper>
        <Icon name="chevron" color={color} />
      </IconWrapper>
    </LinkWrapper>
  );
}
export function ProcedureLinks({ navigate }) {
  const { t } = useTranslation();
  return (
    <ProcedureLinksWrapper>
      <ProcedureLink
        text={t('Consent')}
        onClick={() => navigate('/consent')}
        color="purple"
      />
      <ProcedureLink
        text={t('Hygiene')}
        onClick={() => navigate('/hygiene')}
        color="orange"
      />
      <ProcedureLink
        text={t('Terminology')}
        onClick={() => navigate('/terminology')}
        color="pink"
      />
    </ProcedureLinksWrapper>
  );
}
