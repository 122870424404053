import { useState, useEffect } from 'react';
import _throttle from 'lodash.throttle';

let supportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    // eslint-disable-next-line getter-return
    get: function get() {
      supportsPassive = true;
    },
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {}

let getPosition = () => ({
  x: window.pageXOffset,
  y: window.pageYOffset,
});

let defaultOptions = {
  throttle: 100,
};

export function useWindowScrollPosition(options) {
  let opts = Object.assign({}, defaultOptions, options);

  let [position, setPosition] = useState(getPosition());

  useEffect(() => {
    let handleScroll = _throttle(() => {
      setPosition(getPosition());
    }, opts.throttle);

    window.addEventListener(
      'scroll',
      handleScroll,
      supportsPassive ? { passive: true } : false
    );

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return position;
}
