import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      Share: 'Share',
      'URL added to clipboard': 'URL added to clipboard',
      'Added to clipboard': 'Added to clipboard',
      'Select a video': 'Select a video',
      'Not Found': 'Not Found',
      'Emergency Procedures': 'Emergency Procedures',
      Search: 'Search',
      'Search procedures...': 'Search procedures...',
      'Please check internet connection': 'Please check internet connection',
      'Tap to play button':
        'Tap the play button at the top of the screen to watch the procedure, or use the menu in the footer to navigate the text.',
      'Continue to procedure': 'Continue to Procedure',
      'Welcome to Emergency Procedures.': 'Welcome to Emergency Procedures.',
      'Tap the search':
        "Tap the search bar above to find the procedure you're looking for, or view the full list of procedures.",
      'View Procedure List': 'View Procedure List',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
