import React from 'react';
import { StaticPage } from '../components/StaticPage';

export const Hygiene = ({ navigate }) => (
  <StaticPage
    navigate={navigate}
    title="Hygiene"
    html={`<h1>Standard precautions</h1>

  <p>
  We universally apply measures to prevent infection to the patient and proceduralist. These measures are named standard precautions. If we do not apply standard precautions, we are providing substandard infection prevention to both parties.
  </p>
  
  <p>
  The components of standard precautions are:
  </p>
  
  <p>
    <ul>
      <li>Hand hygiene</li>
      <li>Respiratory hygiene and cough etiquette</li>
      <li>Personal protective equipment</li>
      <li>Aseptic non-touch technique</li>
      <li>Environmental cleaning</li>
      <li>Safe handling and disposal of sharps</li>
      <li>Reprocessing of reusable medical equipment and instruments</li>
      <li>Waste management and appropriate handling of linen</li>
    </ul>
  </p>
  
  <p>
  Procedures should be undertaken in a clean area. All exposed surfaced of hands and wrists should be cleaned with 60-80% ethanol or washed with soap and water if visibly soiled. The nose or mouth should be covered when coughing or sneezing and hands then washed. Intact patient skin should be prepared with 70% isopropyl alcohol with at least 0.5% chlorhexidine (or locally recommended antimicrobial) and all wounds should be irrigated with running tap water (aseptic but not sterile) or sterile saline. Waste products disposed of appropriately with the procedural clinician responsible for the safe use and disposal of all sharps.
  </p>
  <p>
  The procedural clinician also requires an understanding of the principles of aseptic non-touch technique and personal protective equipment. These must be tailored to each procedure by the clinician. 
  </p>

  <h2>Aseptic non-touch technique</h2>

<b>Asepsis means ‘freedom from infectious material’</b>

<p>Aseptic technique aims to prevent pathogenic organisms, in enough quantity to cause infection, from being introduced to susceptible sites. Aseptic does not mean sterile.</p>

<b>Sterile conditions, meaning ‘free from micro-organisms’</b>

<p>Sterile conditions are not possible to achieve in a typical healthcare setting. The commonly used term, ‘sterile technique’ is therefore inaccurate.</p>

<p>We perform invasive procedures (crossing epithelium) using aseptic non-touch technique. We prevent contamination of aseptic sites or wounds (“key sites”) and aseptic procedure equipment that must remain aseptic throughout the procedure (“key parts”). Key parts and key sites may contact each other during the procedure but should not touch anything else (“non-touch”).</p>

<p>If key parts must be touched by hand, the proceduralist’s hands are also treated as a key part. Hand hygiene is increased to mechanical and antimicrobial cleaning (e.g. surgical scrub with running water) and sterile gloves are worn.</p>

<p>If a working field is required this must also be managed as a key part, with an aseptic field created with wide skin preparation and sterile drapes.</p>

<p>If contact between the proceduralist’s body and the aseptic field is possible the proceduralists body must also be treated as a key part and sterile gown is also required.</p>

<p>Examples:</p>

<p><b><u>Simple procedures</u></b> <br/>
A clean area and non-sterile gloves are sufficient for these procedures. 
All key parts are sterile and can be used on key sites without needing to be touched.</p>

<p><b>Example: a venepuncture or cannulation</b> <br/>
A sterile venepuncture needle is housed in a sterile sheath.
It is used on aseptically prepared skin without touching the skin or needle.</p>

<p><b><u>Complex procedures</u></b> <br/>
Complex procedures have multiple key parts which must be touched by hand (requiring sterile gloves), the working field is large (requiring sterile drapes) and contact between the proceduralist’s body and the working field is possible during the procedure (requiring a sterile gown). </p>

<p><b>Example: obtaining central venous access</b> <br/>
The cannulating needle, wire, dilator, catheter and other key parts must be touched by hand (sterile gloves are required), the field is large and will be used to rest key parts during the procedure (a sterile field is required), the proceduralist works within the field and cannot avoid touching it (a sterile gown is required).</p>

<h2>Variations in practise</h2>

<p>Aseptic field size may vary depending on the proficiency of the provider performing the procedure. Highly skilled practitioners require less working space and margin for error and require smaller aseptic fields. </p>

<p>We suggest providers apply these principles independently to each procedure.</p>

<h2>Personal protective equipment</h2>

<p>Personal protective equipment (PPE) refers to barriers used to protect mucous membranes, airways, skin and clothing from contact with infectious agents to protect the proceduralist and patient.</p>

<p>For each procedure we list recommended PPE given below:</p>

<h2>Protecting the proceduralist</h2>

<p><b>Non-sterile gloves: </b>always required due to risk of exposure to infectious material and body substances</p>

<p><b>Aprons: </b>if increased risk of contamination of clothing with infectious material or body substances</p>

<p><b>Surgical mask: </b>required for procedures that generate splashes or sprays</p>

<p><b>Protective eyewear or shield: </b>required for procedures that generate splashes or sprays</p>

<p><b>P2 respirator mask: </b>required for procedures that may aerosolise particles of infectious material</p>

<h2>Protecting the patient</h2>

<p><b>Sterile gloves: </b>required for aseptic non-touch technique requiring hand contact with sterile parts or sites</p>

<p><b>Sterile surgical gown: </b>required for aseptic non-touch technique if body contact with sterile parts or sites is possible</p>

<p><b>Surgical mask: </b>required if the proceduralist respiratory droplets might enter the aseptic field</p>

<p><b>Sterile ultrasound cover and gel: </b>if probe contact with sterile parts or sites is possible during asepsis</p>

<p>Simple procedures can be completed with non-sterile gloves alone (e.g. venepuncture and cannulation) while maintaining the same high standard of infection prevention (standard precautions with aseptic non-touch technique), as complex procedures that require increased higher levels of personal protective equipment.</p>

<h2>Transmission-based precautions</h2>

<p>We employ extra work practices if there is increased risk of specific infections:</p>

<h2>Contact precautions</h2>

<p>Infection transmission by touch or via contact with blood or body substances
e.g. COVID-19, multi-resistant organisms, Clostridium Difficile, Norovirus and skin infections (e.g. impetigo)</p>

<p>
    <ul>
        <li>Single room or cohort</li>
        <li>Gloves & gown</li>
        <li>Surgical mask if agent isolated in sputum</li>
        <li>Single use equipment or reprocess before reuse on next patient</li>
        <li>Visitors use same precautions as staff</li>
    </ul>
</p>

<h2>Droplet precautions</h2>

<p>Infectious particles > 5 microns in size transmitted to susceptible mucosal surfaces < 1m away
e.g. COVID-19, influenza virus and meningococcus.</p>

<p>
    <ul>
        <li>Single room or cohort</li>
        <li>Surgical mask</li>
        <li>Single use equipment or reprocess before reuse on next patient</li>
        <li>Visitors use same precautions as staff, restrict numbers</li>
    </ul>
</p>

<h2>Airborne precautions</h2>

<p>Infectious particles that remain infective over time and distance and are inhaled. 
They may be created by suction, intubation and non-invasive ventilation
e.g. COVID-19 (if aerosolising procedures are performed), Measles (Rubeola) virus, Chickenpox (Varicella), Mycobacterium tuberculosis</p>

<p>
    <ul>
        <li>Single room or cohort</li>
        <li>Negative pressure</li>
        <li>P2 (N95) respirator</li>
        <li>Single use equipment or reprocess before reuse on next patient</li>
        <li>Restrict visitor numbers with precautions as for staff</li>
    </ul>
</p>

<h2>Time-critical procedures</h2>

<p>For time-critical emergency procedures (e.g. needle thoracostomy, thoracotomy in cardiac arrest from penetrating chest trauma, surgical cricothyroidotomy) the increased risk to the patient from infection using non-aseptic technique may be insignificant compared to the risk of delaying the procedure for even a few seconds. In these rare situations, skin cleaning and the preparation of sterile fields can be avoided if it will cause any time delay.</p>

<p>The risks of sharps injuries and exposure to body fluids to the proceduralist increases in such high-pressure situations. The importance of personal protective equipment and safe use and disposal of sharps increases and should not be omitted.</p>

<h2>References</h2>

<p>National Health and Medical Research Council. Australian guidelines for the prevention and control of infection in healthcare. Canberra: NHMRC; 2010. Available from: <a href="https://www.nhmrc.gov.au/about-us/publications/australian-guidelines-prevention-and-control-infection-healthcare-2010">https://www.nhmrc.gov.au/about-us/publications/australian-guidelines-prevention-and-control-infection-healthcare-2010</a></p>

<p>Aseptic Non-Touch Technique (ANNT) [Internet]. 2019. Available from: <a href="http://www.antt.org/">http://www.antt.org/</a></p>

  `}
  />
);
