import React, { useState } from 'react';
import { Layout, PrimaryHeader, OffWhiteContent } from '../components/Layout';
import {
  ProcedureHeader,
  ProcedureContent,
  ProcedureFooter,
} from '../components/ProcedureLayout';
import { useTranslation } from 'react-i18next';
import { Loading as LoadingSpinner } from '../components/Loading';
import { ShareModal } from '../components/ShareModal';
import { VideoOptionsModal } from '../components/VideoOptionsModal';
import { Welcome } from '../components/Welcome';
import _ from 'lodash';
import { useVimeo } from '../components/vimeo';
import { ProcedureNav } from '../components/ProcedureNav';
import { TrackScreenView } from '../components/TrackScreenView'
function NotFound({ navigate }) {
  const { t } = useTranslation();
  return (
    <Layout
      header={
        <PrimaryHeader
          title={t('Not Found')}
          onBackClick={() => navigate('/')}
          onSearchClick={() => navigate('/search')}
        />
      }
      content={
        <OffWhiteContent>
          <h1>Not found</h1>
        </OffWhiteContent>
      }
    />
  );
}
function Loading({ navigate }) {
  const { t } = useTranslation();
  return (
    <Layout
      header={
        <PrimaryHeader
          title={t('Please Wait...')}
          onBackClick={() => navigate('/')}
          onSearchClick={() => navigate('/search')}
        />
      }
      content={
        <OffWhiteContent>
          <LoadingSpinner />
        </OffWhiteContent>
      }
    />
  );
}
function ProcedureFound({ procedure, navigate }) {
  const [isOpen, setOpen] = useState(false);
  const [showVideoOptions, setVideoModal] = useState(false);
  const [showShareModal, setShareModal] = useState(false);
  const { t } = useTranslation();
  const [video] = useVimeo(_.get(procedure, 'content.video[0]', '')); // useVimeoThumnail(_.get(procedure, 'content.video[0].data', ''));
  const screenName = `/procedures/${_.kebabCase(procedure.heading)}`;
  return (
    <>
      <TrackScreenView screenName={screenName} /> 
      <VideoOptionsModal
        show={showVideoOptions}
        screenName={screenName}
        videos={procedure.content.video}
        hide={() => setVideoModal(false)}
      />
      <ShareModal
        show={showShareModal}
        procedure={procedure}
        hide={() => setShareModal(false)}
      />
      <ProcedureNav procedure={procedure} isOpen={isOpen} setOpen={setOpen} />
      <Welcome
        storageKey="welcome"
        text={t('Tap to play button')}
        button={t('Continue to procedure')}
      />
      <Layout
        header={
          <ProcedureHeader
            backgroundUrl={video.thumbnail_large}
            title={procedure.heading}
            onBackClick={() => navigate('/')}
            onSearchClick={() => {
              navigate('/search');
            }}
            videosAvailable={procedure.content.video}
            onPlayClick={() => setVideoModal(true)}
          />
        }
        content={
          <ProcedureContent
            navigate={navigate}
            html={procedure.content.data}
            videos={procedure.content.video}
          />
        }
        footer={
          <ProcedureFooter
            videos={procedure.content.video}
            onSectionsClick={() => {
              setOpen(!isOpen);
            }}
            videosAvailable={procedure.content.video}
            onVideoClick={() => setVideoModal(true)}
            onShareClick={() => {
              if (navigator.share) {
                navigator
                  .share({
                    title: procedure.heading,
                    text: `Emergency Procedures: ${procedure.heading}`,
                    url: window.location.href,
                  })
                  .then(() => console.log('Successful share'))
                  .catch((error) => console.log('Error sharing', error));
              } else {
                setShareModal(true);
              }
            }}
          />
        }
      />
    </>
  );
}

export function Procedure({
  procedures,
  procedureId,
  isLoading,
  navigate,
  ...props
}) {
  const procedure = procedures[procedureId];
  if (isLoading) return <Loading />;
  if (!procedure) return <NotFound navigate={navigate} />;
  return <ProcedureFound procedure={procedure} navigate={navigate} />;
}
