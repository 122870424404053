import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const FooterPlayButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

const Count = styled.div`
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary};
  padding: 2px;
  width: 16px;
  font-size: 0.6em;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  top: -5px;
  right: -7px;
  display: flex;
  justify-content: center;
`;
export function FooterPlayButton({ videos, onClick }) {
  return (
    <FooterPlayButtonWrapper onClick={onClick}>
      <Count>{videos.length}</Count>
      <Icon name="videos" color="grey" />
    </FooterPlayButtonWrapper>
  );
}
