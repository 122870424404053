import React from 'react';

function Play({ color, width, viewBox, height }) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${viewBox}`}
      version="1.1"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          id="Path"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="31 25 54.166667 39.7424245 31 54.4848489"
        ></polygon>
      </g>
    </svg>
  );
}
Play.defaultProps = {
  width: 40,
  height: 40,
  viewBox: '60 60',
};
export default Play;
