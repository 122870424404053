import { useAsync } from "./useAsync";
const createUrl = (path) =>
  `https://aci.health.nsw.gov.au/api/eci/${path}?key=kjbhk23456odmfy39vjn67vh`;
const get = (path) => async () => {
  const url = createUrl(path);
  try {
    const res = await fetch(url);
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("Unrecognised response");
    }
  } catch (e) {
    throw new Error(e);
  }
};
const getProcedures = get("procedure");
export function useProcedures(procedures) {
  return useAsync({
    promiseFn: getProcedures,
    initialValue: !procedures.length ? undefined : procedures,
  });
}
