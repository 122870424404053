import React, { useMemo, useState } from 'react';
import { Layout, PrimaryHeader, OffWhiteContent } from '../components/Layout';
import { useTranslation } from 'react-i18next';
import { SearchField } from '../components/SearchField';
import { Welcome } from '../components/Welcome';
import { ProceduresList } from '../components/ProceduresList';
import styled from 'styled-components';

function SearchProcedures({ data, onChange, value }) {
  const { t } = useTranslation();
  return (
    <SearchField
      placeholder={t('Search procedures...')}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
function filterPredicate(searchTerm) {
  return (item) => {
    return (
      item.heading.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.content.data.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
}
function sortComparator(a, b) {
  if (a && b && a.heading && b.heading) {
    if (a.heading.toLowerCase() < b.heading.toLowerCase()) {
      return -1;
    }
    if (a.heading.toLowerCase() > b.heading.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}
const Img = styled.img`
  width: 80px;
  height: 20.23px;
  padding-left: 10px;
  align-self: center;
`;
const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.offwhite};
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 50px;
`;
const Text = styled.span`
  color: ${({ theme }) => theme.darkGrey};
`;
const PoweredByMedApps = () => (
  <Wrapper>
    <Text>Powered by</Text>
    <Text>
      <Img
        alt=""
        src={require('../medapps-logo-blue.png')}
        onClick={() => window.open('https://med.app')}
      />
    </Text>
  </Wrapper>
);

export function Procedures({ procedures, isLoading, error }) {
  const [searchTerm, updateSearchTerm] = useState('');
  const filtered = useMemo(() => {
    if (!procedures) return [];
    if (!searchTerm) return procedures;
    return procedures.filter(filterPredicate(searchTerm)).sort(sortComparator);
  }, [procedures, searchTerm, sortComparator, filterPredicate]);
  const { t } = useTranslation();
  return (
    <>
      <Welcome
        title={t('Welcome to Emergency Procedures.')}
        storageKey="home-welcome"
        text={t('Tap the search')}
        button={t('View Procedure List')}
      />
      <Layout
        header={<PrimaryHeader title={t('Emergency Procedures')} />}
        content={
          <OffWhiteContent>
            <SearchProcedures onChange={updateSearchTerm} value={searchTerm} />
            <ProceduresList
              data={error && !filtered.length ? [] : filtered}
              isLoading={isLoading}
            />
          </OffWhiteContent>
        }
        footer={<PoweredByMedApps />}
      />
    </>
  );
}
