import React from 'react';
import styled, { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';

const FooterSectionWrapper = styled.div``;
export const FooterSectionsButton = withTheme(function _FooterSectionsButton({
  onClick,
  theme,
}) {
  return (
    <FooterSectionWrapper onClick={onClick}>
      <FontAwesomeIcon icon={faListUl} color={theme.icon} width="lg" />
    </FooterSectionWrapper>
  );
});
