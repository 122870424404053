import { useMemo } from 'react';
import { useProcedures } from './useProcedures';

function filterPredicate(searchTerm) {
  return (item) => {
    return (
      item.heading.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.content.data.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
}
function sortComparator(a, b) {
  if (a && b && a.heading && b.heading) {
    if (a.heading.toLowerCase() < b.heading.toLowerCase()) {
      return -1;
    }
    if (a.heading.toLowerCase() > b.heading.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}

export function useProceduresSearch(procedures, searchTerm) {
  const { data, isLoading, error } = useProcedures(procedures);
  const filtered = useMemo(() => {
    if (!data) return [];
    if (!searchTerm) return data;
    return data.filter(filterPredicate(searchTerm)).sort(sortComparator);
  }, [data, searchTerm, sortComparator, filterPredicate]);
  return [filtered, isLoading, error];
}
