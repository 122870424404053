import React from 'react';
import { StaticPage } from '../components/StaticPage';

export const Terminology = ({ navigate }) => (
  <StaticPage
    navigate={navigate}
    title="Terminology"
    html={`<h1>Guideline structure</h1> <br />

  <h2>
      Procedure
  </h2>
  <p>
  Procedure includes any situation in which there is a potential for contact between the skin of the healthcare worker and the patient’s tissues, body cavities or organs, either directly or via surgical instruments or therapeutic devices.  
  </p>
  
  <h2>Indications</h2> 
  <p>
  Indications occurring in emergency practice at least occasionally are listed <br />
  Indications occurring outside the emergency department are not listed <br />
  More common indications are listed higher up
  </p>
   
  <h2>Contraindications</h2>
  <p>
  Absolute contraindications are <b>highlighted in bold</b> <br />
  Contraindications we consider more significant are listed higher up
  </p>

  <h2>Alternatives</h2>
  <p>
  Alternatives to the proposed procedure we recommend should be considered and discussed with the patient. <br />
  The risks and benefits of the alternatives should be balanced against those of the procedure.   
  </p>

  <h2>
      Consent
  </h2>
  <p>
  We describe how consent should be recorded for the procedure.
  </p>
  <p><b>We have separated the principles of consent into a separate overview document applicable for all procedures. Providers should have a clear understanding of these key concepts and apply them independently for each procedure.</b></p>

  <h2>Complications</h2>
  <p>
  We list possible complications of a competently performed procedure listed chronologically. <br />
  These complications should be discussed with the patient during a non-emergency consent process. <br />
  Adverse events which should not occur and might be considered clinical negligence are not listed.
  </p>
  
  
  <h2>Procedural hygiene</h2>
  <p>For each procedure, we list a level of recommended procedural hygiene as follows:</p>
  
  <p>
    <ul>
      <li>Standard precautions</li>
      <li>Aseptic non-touch technique (for invasive procedures)</li>
      <li>Recommended personal protective equipment (listed)</li>
    </ul>
  </p>

  <p>We do not explain each step (e.g. cleaning the skin) or list specific pieces of equipment (e.g. drape) required to achieve the recommended level of hygiene</p>

  <p><b>We have separated the principles of procedural hygiene into a separate overview documents applicable for all procedures. Providers should have a clear understanding of these key concepts and apply them independently for each procedure.</b></p>

  <h2>Procedure</h2>

  <p>We describe the procedure in six sections:</p>

  <p><b>Area: </b>suitable areas to perform the procedure</p>
  <p><b>Staff: </b>proceduralist and additional staff required</p>
  <p><b>Equipment: </b>listed in order of use</p>
  <p><b>Medications: </b>listed as dose per kilo titrated to endpoint</p>
  <p><b>Positioning: </b>non-medical terminology</p>
  <p><b>Sequence: </b>stepwise tasks to complete procedure</p>

  <p>If continuous cardiac monitoring is required, this is mentioned in the area section</p>

  <h2>Post-procedure care</h2>

  <p>Post-procedure care is detailed in four areas. We detail the following where required:</p>

  <p>
    <ul>
        <li>Observation and monitoring</li>
        <li>Ongoing care</li>
        <li>Patient advice including pain relief</li>
        <li>Documentation completion of procedure, complications, procedure specific aspects</li>
    </ul>
  </p>

  <h2>Tips</h2>

  <p>We list practical evidence-based or expert consensus advice</p>

  <h2>Discussion</h2>
  <p>Our rationale and key evidence in debatable or contentious areas are explained.</p>
`}
  />
);
