import React from 'react';

function Search({ color, width, viewBox, height }) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${viewBox}`}
      version="1.1"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="search"
          transform="translate(1.000000, 1.000000)"
          stroke={color}
          strokeWidth="2"
        >
          <path d="M16,17 L23,24" id="Path"></path>
          <circle id="Oval" cx="9.5" cy="9.5" r="9.5"></circle>
        </g>
      </g>
    </svg>
  );
}
Search.defaultProps = {
  width: 25,
  height: 26,
  viewBox: '25 26',
};
export default Search;
