import React, { Component, useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { Procedures } from "./pages/Procedures";
import { Procedure } from "./pages/Procedure";
import { Consent } from "./pages/Consent";
import { Hygiene } from "./pages/Hygiene";
import { Terminology } from "./pages/Terminology";
import { Privacy } from "./pages/Privacy";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { Search } from "./pages/Search";
import theme from "./theme";
import { Router, LocationProvider, createHistory } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./animations.css";
import useAppState from "./state";
import { useAsync } from "./components/useAsync";
import * as Sentry from "@sentry/browser";
const history = createHistory(window);

history.listen(() => {
  if (!history.location.pathname.includes("/procedure/")) {
    window.gtag("event", "page_view", {
      page_title: history.location.pathname,
      page_location: history.location.pathname,
      page_path: history.location.pathname,
      send_to: "UA-33297057-16",
    });
  }
  window.scrollTo(0, 0);
});

const createUrl = (path) =>
  `https://aci.health.nsw.gov.au/api/eci/${path}?key=kjbhk23456odmfy39vjn67vh`;
const get = (path) => async () => {
  const url = createUrl(path);
  try {
    const res = await fetch(url);
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("Unrecognised response");
    }
  } catch (e) {
    throw new Error(e);
  }
};
const getProcedures = get("procedure");
function useProcedures(procedures) {
  return useAsync({
    promiseFn: getProcedures,
    initialValue: !procedures.length ? undefined : procedures,
  });
}
const FadeTransitionRouter = (props) => (
  <LocationProvider history={history}>
    {({ location }) => (
      <TransitionGroup className="transition-group">
        <CSSTransition key={location.key} classNames="fade" timeout={500}>
          {/* the only difference between a router animation and
              any other animation is that you have to pass the
              location to the router so the old screen renders
              the "old location" */}
          <Router location={location} className="router">
            {props.children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </LocationProvider>
);

function sortComparator(a, b) {
  if (a && b && a.heading && b.heading) {
    if (a.heading.toLowerCase() < b.heading.toLowerCase()) {
      return -1;
    }
    if (a.heading.toLowerCase() > b.heading.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}
const Routes = () => {
  const [state, dispatch] = useAppState();
  const { data, isLoading, error } = useProcedures(state.procedures);
  useMemo(() => {
    if (!isLoading && data) {
      dispatch({ type: "procedures", payload: data.sort(sortComparator) });
    }
  }, [isLoading, data]);
  return (
    <FadeTransitionRouter>
      <Procedures
        path="/"
        procedures={state.procedures}
        error={error}
        isLoading={isLoading}
      />
      <Search
        path="/search"
        procedures={state.procedures}
        error={error}
        isLoading={isLoading}
      />
      <Procedure
        path="/procedure/:procedureId"
        procedures={state.proceduresMap}
        error={error}
        isLoading={isLoading}
      />
      <Consent path="/consent" />
      <Hygiene path="/hygiene" />
      <Terminology path="/terminology" />
      <Privacy path="/privacy" />
      <TermsAndConditions path="/terms-conditions" />
    </FadeTransitionRouter>
  );
};
class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") {
      this.setState({ error });
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    } else {
      throw error;
    }
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
      );
    } else {
      return this.props.children;
    }
  }
}
export default App;
