import React from 'react';
import { StaticPage } from '../components/StaticPage';

export const Consent = ({ navigate }) => (
  <StaticPage
    navigate={navigate}
    title="Consent"
    html={`
  <h1>Consent</h1>

  <p>
  Obtaining consent for procedures is a legal and ethical requirement and an accepted part of good medical practice. Excluding medical emergencies, patients must be provided with adequate information about a procedure to enable them to make informed decisions. This includes the benefits, possible adverse effects or complications, alternatives and the likely result if the procedure is not performed. 
  </p>

  <p>
  For a patient’s consent to be valid all of the following criteria must be met:
  </p>
  
  <p>
      <ul>
        <li>The person must have the capacity to give consent</li>
        <li>Adequate information must be provided</li>
        <li>The patient must be informed in a way the patient can understand</li>
        <li>Consent must be freely given</li>
        <li>Consent must be specific to the procedure</li>
      </ul>    
  </p>
  
  <h2>Capacity</h2>
  
  <p>
  Capacity to provide consent means the patient can comprehend and consider the information in order to reach a decision. Some patients will have the capacity to make some, but not all, decisions concerning their care. 
  </p>
  <p>
  Common reasons a patient may lack capacity include: 
  </p>
  <p>
    <ul>
    <li>Temporary factors such as the patient’s medical condition (e.g. intoxication, reduced level of consciousness)</li>
    <li>Intellectual impairment, dementia, or brain damage</li>
    <li>Mental illness currently impairing decision making</li>
    <li>Children (generally aged 14 or less)</li>
    </ul> 
  </p>
  <p>An adult patient with capacity is entitled to decline a medical procedure. Treating a competent patient who has declined treatment could constitute assault.</p>

  <p>Outside of emergency situations, if a person is incapable of giving consent, the consent of the patient’s ‘person responsible’ will be required. The person responsible for a patient will often be the patient’s spouse or de facto, a parent, guardian, guardianship board or local authority.</p>

  <h2>Medical emergencies</h2>

  <p>In an emergency, where the patient is unable to give consent, a procedure may be carried out immediately if it is required to:</p>

  <p><ul>
  <li>Save the person’s life</li>
  <li>Prevent serious injury, or</li>
  <li>Prevent the patient from suffering significant pain and distress</li>
  </ul></p>

  <p>We often perform procedures on seriously ill or injured patients under these circumstances (e.g. chest drains insertion in an unconscious trauma patient). Brief verbal discussion of the procedure with the patient is always recommended if the situation allows.</p>

  <p>Example: when intubating a conscious patient for severe respiratory failure, it would be reasonable and sufficient to inform the patient: ‘We are going to give you an anaesthetic, take control of your breathing and look after you in the intensive care unit. It is the best way to treat you. We do this regularly and will take good care of you’.
  </p>

  <h2>Documenting consent</h2>

  <p>Consent is a process (not a signature) which may be provided orally or implied by body language.</p>

  <p>Australian law does not require consent in writing. However, obtained consent in writing may assist practitioners provide a comprehensive discussion of procedural risks and benefits with the patient. This may also be useful if reviewed later, supporting the view that treatment had been discussed with the patient and that valid consent has been obtained.</p>

  <p>We suggest written consent using a state prescribed consent form for major procedures especially those including intravenous sedation and procedures with significant risks. State legislation varies and may mandate additional requirements for consent depending on your region of practice. If you have discussed consent with a patient, this process should also be document in the medical notes.</p>

  <p>The consent levels used in this guide are as follows.</p>

  <h3>Medical emergency</h3>
  <p>Consent is not required if the patient lacks capacity or is unable to consent
  Brief verbal discussion is recommended if the situation allows</p> 
  
  <h3>Verbal consent</h3>
  <p>
  Less complex non-emergency procedures with low risks of complications
  </p>
  
  <h3>Written consent</h3>
  <p>
  More complex non-emergency procedures with higher risks of complications
  </p>
  <p>
  Pre-prepared material (translated where relevant) about a procedure or treatment may be useful as a means of stimulating discussion and for guiding the clinician when informing the patient. Interpreters should be used for any non-English speaking patients, or for those who request it
  </p>

  <h2>
    Advance care directives
  </h2>
  <p>
  A medical practitioner can assume a signed advance care directive will have been made by a competent patient to be used when they are not competent and should comply with that advanced care directive provided it applies to the current situation. 
  </p>
  <p>
  A medical practitioner should not provide futile treatment or perform a procedure where there is a valid direction by the patient that such treatment is not to be provided in any circumstances.
  </p>

  <h2>Minors</h2>

  <p>Children cannot refuse life-saving or health-saving treatment until they are 18 years or older. The protective powers of the state override a minor’s refusal of life-saving treatment. This is in contrasts to adults (18 years and over) who can decline life-saving treatment, even if this results in their death.</p>

  <p>Such treatment of children may be provided without the consent of the parent or guardian.</p>

  <p>Excluding life-saving or health-saving treatment, children are given autonomy based on their level of maturity. A competent child (usually aged 14 and above) may consent to their own treatment. However, it is prudent for practitioners or hospitals to also obtain the consent of the parent or guardian, unless the patient objects. Non-emergency treatment of children who are not competent to consent requires the consent of the parent or guardian.</p>

  <h2>References</h2>

<div><p>Australian Law Reform Commission. Equity, capacity and disability in Commonwealth laws: Final report. Canberra: Commonwealth of Australia; 2014 Aug. 324 p. ALRC Report 124. Available from: <a href="https://www.alrc.gov.au/publication/equality-capacity-and-disability-in-commonwealth-laws-alrc-report-124/">https://www.alrc.gov.au/publication/equality-capacity-and-disability-in-commonwealth-laws-alrc-report-124/</a></p></div>

<div><p>NSW Ministry of Health. Consent to medical and healthcare treatment manual. Sydney: Government of NSW; 2020. Available from: <a href="https://www.health.nsw.gov.au/policies/manuals/Pages/consent-manual.aspx">https://www.health.nsw.gov.au/policies/manuals/Pages/consent-manual.aspx</a></p></div>

</div><p>NSW Ministry of Health. Clinical Procedure Safety. Sydney: Government of NSW; 2017 Sept. Policy directive: PD2017_032. Available from: <a href="https://www1.health.nsw.gov.au/pds/Pages/doc.aspx?dn=PD2017_032">https://www1.health.nsw.gov.au/pds/Pages/doc.aspx?dn=PD2017_032</a></p></div>
`}
  />
);
