import { useEffect, useState } from 'react';
import _ from 'lodash';

async function getVimeoData(id) {
  const response = await fetch(`https://vimeo.com/api/v2/video/${id}.json`, {
    accept: 'application/json',
  });
  if (!response.ok) throw Error('unrecognised response');
  return response.json();
}

export function useVimeoThumnail(id) {
  const [url, setUrl] = useState('');
  const data = useVimeo(id);
  useEffect(() => {
    if (data) {
      setUrl(_.first(data).thumbnail_large);
    }
  }, [data]);
  return url;
}

export function useVimeo(id) {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getVimeoData(id)
      .then((data) => {
        if (data) {
          setData(data[0]);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
        console.error(e);
      });
  }, [id]);
  return [data, loading, error];
}
