import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const FooterShareButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

export function FooterShareButton({ onClick }) {
  return (
    <FooterShareButtonWrapper onClick={onClick}>
      <Icon name="share" color="grey" />
    </FooterShareButtonWrapper>
  );
}
