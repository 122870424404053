import {useEffect} from 'react'

export function TrackScreenView({
    screenName
}) {
    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_title: screenName,
            page_location: screenName,
            page_path: `/${screenName}`,
            send_to: 'UA-33297057-16'
        })
    }, [screenName]);
    return null;
}