const theme = {
  white: '#fff',
  boxShadow: 'box-shadow: -5px -7px 30px -3px rgba(0,0,0,0.15)',
  background: '#E7E7E7',
  offwhite: '#f7f7f7',
  primary: '#006f9a', //'#186992',
  primaryHighlighted: '#035e82',
  grey: 'rgba(73,84,90,0.51)',
  midGrey: '#49545A',
  darkGrey: 'rgb(34, 34, 34)',
  animatedDarkGrey: (opacity) => `rgba(34, 34, 34, ${opacity})`,
  peach: '#f3333a',
  black: '#000',
  headingBorderColor: '#e9e9e9',
  purple: '#623298',
  orange: '#ff7827',
  pink: '#f6318b',
  icon: '#49545A',
  border: '#e9e9e9',
  fonts: {
    size: {
      small: '0.9em',
      large: '24pt',
      medium: '1.2rem',
      h2: '1.2rem',
      p: '1rem',
    },
    bold: 600,
    default: "'Source Sans Pro', sans-serif;",
  },
  red: `rgba(174,36,53,1)`,
};

export default theme;
