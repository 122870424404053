import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import color from 'color';
import { ProcedureLinks } from './ProcedureLinks';
import { ProcedurePlayButton } from './ProcedurePlayButton';
import { FooterPlayButton } from './FooterPlayButton';
import { FooterShareButton } from './FooterShareButton';
import { FooterSectionsButton } from './FooterSectionsButton';

const ProcedureHeaderWrapper = styled.div`
  @supports (padding: max(0px)) {
    padding-top: max(0px, env(safe-area-inset-top));
  }
  ${({ backgroundUrl, theme }) => {
    if (backgroundUrl) return `background: url(${backgroundUrl});`;
    return `background-color:${theme.primary}`;
  }}
  background-repeat: no-repeat;
  background-size: cover;
`;
const ProcedureHeaderNavWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`;
const ProcedureBackgroundFilter = styled.div`
  background-color: ${({ theme }) =>
    color(theme.darkGrey).alpha(0.4).hsl().string()};
  height: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;
const ProcedureHeaderNav = ({ onBackClick, onSearchClick }) => {
  return (
    <ProcedureHeaderNavWrapper>
      <Icon
        name="back"
        width={22}
        height={22}
        onClick={onBackClick}
        color="white"
      />
      <Icon
        name="search"
        width={22}
        height={22}
        onClick={onSearchClick}
        color="white"
      />
    </ProcedureHeaderNavWrapper>
  );
};
const ProcedureTitle = styled.span`
  align-self: flex-start;
  text-align: left;
  padding: 20px;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: ${({ theme }) => '2rem'};
  font-weight: ${({ theme }) => theme.fonts.bold};
`;
export function ProcedureHeader({
  title,
  backgroundUrl,
  onPlayClick,
  onBackClick,
  onSearchClick,
  videosAvailable,
}) {
  return (
    <ProcedureHeaderWrapper backgroundUrl={backgroundUrl}>
      <ProcedureBackgroundFilter>
        <ProcedureHeaderNav
          onBackClick={onBackClick}
          onSearchClick={onSearchClick}
        />
        {videosAvailable.length ? (
          <ProcedurePlayButton onClick={onPlayClick} />
        ) : null}
        <ProcedureTitle>{title}</ProcedureTitle>
      </ProcedureBackgroundFilter>
    </ProcedureHeaderWrapper>
  );
}
const ProcedureContentWrapper = styled.div`
  margin-top: -60px;
  padding-bottom: 47px;
`;

export const ProcedureHtml = styled.div`
  background-color: ${({ theme }) => theme.offwhite};
  padding: 20px 20px 20px 20px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.default};
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
  h1 {
    color: ${({ theme }) => theme.peach};
    width: 100%;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    font-size: ${({ theme }) => theme.fonts.size.h1};
  }
  h2,
  h3 {
    color: ${({ theme }) => theme.peach};
    width: 100%;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    font-size: ${({ theme }) => theme.fonts.size.h2};
  }
  h1:after,
  h2:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    border-bottom: 3px solid ${({ theme }) => theme.headingBorderColor};
  }
  p {
    font-size: ${({ theme }) => theme.fonts.size.p};
    line-height: 1.4rem;
  }
  a {
    word-wrap: break-word;
  }
  ul {
    padding-left: 10px;
  }
  ol li,
  ul li {
    color: #3c3c3c;
    list-style: none;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  li:before {
    color: ${({ theme }) => theme.primary};
    content: '•';
    font-size: 1em;
    padding-right: 1.1225em;
    position: relative;
    height: 100%;
    top: 0em;
  }
  strong {
    font-weight: ${({ theme }) => theme.fonts.bold};
  }
  img {
    width: 100%;
    height: auto;
  }
`;

export function ProcedureContent({ html, navigate, videos }) {
  return (
    <ProcedureContentWrapper>
      <ProcedureLinks navigate={navigate} />
      <ProcedureHtml dangerouslySetInnerHTML={{ __html: html }} />
    </ProcedureContentWrapper>
  );
}
const ProcedureFooterWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: 40px;
  }
  @supports (padding: max(0px)) {
    padding-bottom: max(20px, env(safe-area-inset-top));
  }
  border-top: 2px solid ${({ theme }) => theme.border};
  width: 100%;
  background-color: ${({ theme }) => theme.offwhite};
  z-index: 9;
`;

const ProcedureFooterContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

export function ProcedureFooter({
  videos,
  onVideoClick,
  onSectionsClick,
  onShareClick,
  videosAvailable,
}) {
  return (
    <ProcedureFooterWrapper>
      <ProcedureFooterContentWrapper>
        <FooterSectionsButton onClick={onSectionsClick} />
        {videosAvailable.length !== 0 && (
          <FooterPlayButton videos={videos} onClick={onVideoClick} />
        )}
        <FooterShareButton videos={videos} onClick={onShareClick} />
      </ProcedureFooterContentWrapper>
    </ProcedureFooterWrapper>
  );
}
