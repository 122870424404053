import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const SearchWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.white};
  padding: 10px;
  justify-content: space-between;
  align-items: center;
`;
const Input = styled.input`
  flex-grow: 1;
  border: none;
  padding: 10px;
  font-size: 1em;
`;

export function SearchField({ placeholder, value, onChange, autoFocus }) {
  const input = React.createRef();
  return (
    <SearchWrapper>
      <Input
        type="text"
        ref={input}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      <Icon
        onClick={() => {
          input.current.focus();
        }}
        name="search"
        color="midGrey"
        width={22}
        height={22}
        viewBox={`25 26`}
      />
    </SearchWrapper>
  );
}
