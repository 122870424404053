import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useVimeo } from './vimeo';
import { Modal, Prompt } from './Modal';
import { BackButton } from './Layout';
import Icon from './Icon';
const OptionWrapper = styled.div`
  background-color: ${({ theme }) => theme.offwhite};
  padding: 15px;
  justify-content: space-evenly;
  display: flex;
  &hover {
    ${({ theme }) => `background-color: ${theme.primary}`};
  }
`;
const DurationText = styled.span`
  padding: 0px 2px;
`;
const Duration = ({ duration }) => {
  const mins = Math.floor(duration / 60);
  const secs = duration % 60;
  return (
    <DurationText>
      ({mins}:{secs})
    </DurationText>
  );
};
const VideoIcon = styled.div`
  padding: 0px 5px;
`;
function Option({ id, onClick }) {
  const [data, loading, error] = useVimeo(id);
  if (error) return null;
  return (
    !loading && (
      <OptionWrapper onClick={() => onClick(data)}>
        <VideoIcon>
          <Icon name="videos" color="grey" />
        </VideoIcon>
        {data.title}
        <Duration duration={data.duration} />
      </OptionWrapper>
    )
  );
}
const IFrame = styled.iframe`
  border: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 33.25%;
`;
const Theatre = styled.div`
  background: ${({ theme }) => theme.grey};
  height: 100%;
  display: flex;
  align-items: center;
`;

export function VideoOptionsModal({ videos, show, hide, screenName }) {
  const { t } = useTranslation();
  const [selectedVideo, play] = useState(null);
  const [firstVideo] = useVimeo(videos[0]); 
  useEffect(() => {
    if (firstVideo && show && videos.length === 1) {
      play(firstVideo)
      window.gtag('event', 'play-video', {
        event_category: 'videos',
        event_label: screenName,
        event_value: firstVideo.title
      });
    }
  }, [show, screenName, videos, firstVideo])

  if (selectedVideo) {
    return (
      <Modal
        show={show}
        hide={() => {
          play(null);
        }}
      >
        <Theatre
          onClick={(e) => {
            hide();
          }}
        >
          <BackButton onClick={() => play(null)} />
          {selectedVideo && (
            <IFrame
              title="vimeo"
              src={
                process.env.NODE_ENV === 'production'
                  ? `https://player.vimeo.com/video/${selectedVideo.id}?playsinline=0`
                  : `https://player.vimeo.com/video/361724439?playsinline=0`
              }
              webkitallowfullscreen="webkitallowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              allowFullScreen
              width="100%"
            ></IFrame>
          )}
        </Theatre>
      </Modal>
    );
  }
  return (
    <Modal show={show} hide={hide}>
      {videos.length > 1 && (
        <Prompt title={t('Select a video')}>
          {videos.map((video) => (
            <Option key={video} id={video} onClick={(vimeoData) => {
              play(vimeoData)
              window.gtag('event', 'play-video', {
                event_category: 'videos',
                event_label: screenName,
                event_value: vimeoData.title
              });
            }} />
          ))}
        </Prompt>
      )}
    </Modal>
  );
}
