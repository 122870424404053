import React from 'react';

function Videos({ width, height, color, viewBox }) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${viewBox}`}
      version="1.1"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="videos"
          transform="translate(1.000000, 1.000000)"
          stroke="#49545A"
          strokeWidth="2"
        >
          <rect
            id="Rectangle"
            x="-1.0658141e-14"
            y="-1.0658141e-14"
            width="25"
            height="25"
            rx="12.5"
          ></rect>
          <polygon
            id="Path"
            points="9.25925926 6.66666667 18.4259259 12.5 9.25925926 18.3333333"
          ></polygon>
        </g>
      </g>
    </svg>
  );
}

Videos.defaultProps = {
  width: 20,
  height: 20,
  viewBox: '27 27',
};

export default Videos;
