import React, { useMemo, useState, useEffect } from 'react';
import cheerio from 'cheerio';
import styled, { createGlobalStyle } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useWindowScrollPosition } from './useWindowScrollPosition';

const getData = (node) => {
  if (!node) return;
  return node.data || getData(node.children[0]);
};
function useSections(procedure) {
  const [sections, setSections] = useState([]);
  useMemo(() => {
    const $ = cheerio.load(procedure.content.data);
    const h2s = $('h2,h3');
    setSections(
      new Array(h2s.length).fill(undefined).map((_, index) => {
        return getData(h2s[index]);
      })
    );
  }, [procedure]);
  return [sections];
}
const SectionsWrapper = styled(animated.div)`
  background-color: ${({ theme }) => theme.animatedDarkGrey(0.9)};
  background: ${({ theme }) => theme.animatedDarkGrey(0.9)};
  height: 100%;
  position: fixed;
  width: 250px;
  left: -1000px;
  top: 0px;
  z-index: 10;
  overflow-y: scroll;
  padding-bottom: 300px;
`;
const Section = styled.div`
  color: ${({ theme, active }) => (active ? theme.pink : theme.white)};
  padding: 20px 30px 20px 10px;
`;

const GlobalStyle = createGlobalStyle`body {
  overflow: hidden;
}`;
const CaptureTouches = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
`;
function scrollToElement(section) {
  const element = getSectionElement(section);
  if (!element) return;
  element.scrollIntoView();
}
function getSectionElement(section) {
  let heading = document.evaluate(
    `//h2[text()="${section}"]`,
    document,
    null,
    XPathResult.ANY_TYPE,
    null
  );
  let element = heading.iterateNext();
  if (!element) {
    heading = document.evaluate(
      `//h3[text()="${section}"]`,
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    );
    element = heading.iterateNext();
  }
  if (!element) {
    heading = document.evaluate(
      `//h2/strong[text()="${section}"]`,
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    );
    element = heading.iterateNext();
  }
  return element;
}
function getSectionPosition(section) {
  const element = getSectionElement(section);
  if (!element) return;
  const rect = element.getBoundingClientRect();
  return rect.top + window.scrollY;
}
function getClosest(numbers, myNumber) {
  let distance = Math.abs(numbers[0] - myNumber);
  let idx = 0;
  for (let c = 1; c < numbers.length; c++) {
    let cdistance = Math.abs(numbers[c] - myNumber);
    if (cdistance < distance) {
      idx = c;
      distance = cdistance;
    }
  }
  const theNumber = numbers[idx];
  return theNumber;
}

export function ProcedureNav({ procedure, isOpen, setOpen }) {
  const [sections] = useSections(procedure);
  const [isReady, setReady] = useState(false);
  useEffect(() => {
    if (isOpen) setMenu({ left: '0px' });
    if (!isOpen) setMenu({ left: '-1000px' });
  }, [isOpen, procedure]);
  const [propsMenu, setMenu] = useSpring(() => ({ left: '-1000px' }));
  const [active, setActive] = useState(sections[0]);
  const [sectionPositions, setSectionPosition] = useState([]);
  useEffect(() => {
    // ready to scan DOM for section Headings Y coords.
    setTimeout(() => setReady(true), 500);
  }, [procedure]);
  useMemo(() => {
    const result = sections
      .map((section) => getSectionPosition(section))
      .filter((n) => n);
    if (result.length) setSectionPosition(result);
  }, [sections, isReady]);
  const { y } = useWindowScrollPosition();
  useMemo(() => {
    const closest = getClosest(sectionPositions, y);
    const index = sectionPositions.findIndex((n) => n === closest);
    setActive(sections[index]);
  }, [y]);
  return (
    <>
      {isOpen && <GlobalStyle />}
      {isOpen && (
        <CaptureTouches
          onClick={() => {
            setOpen(false);
            setMenu({ left: '-1000px' });
          }}
        />
      )}
      <SectionsWrapper style={propsMenu} onClick={(e) => e.stopPropagation()}>
        {sections.map((section) => (
          <Section
            key={section}
            active={active === section}
            onClick={() => {
              setOpen(false);
              setActive(section);
              setMenu({ left: '-1000px' });
              scrollToElement(section);
            }}
          >
            {section}
          </Section>
        ))}
      </SectionsWrapper>
    </>
  );
}
