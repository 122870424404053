import React from 'react';
import styled, { withTheme } from 'styled-components';
import Triangle from './svg/Triangle';
import Search from './svg/Search';
import Play from './svg/Play';
import Back from './svg/Back';
import Videos from './svg/Videos';
import Share from './svg/Share';
import Info from './svg/Info';

const map = {
  chevron: Triangle,
  search: Search,
  back: Back,
  play: Play,
  videos: Videos,
  share: Share,
  info: Info,
};

const noop = () => null;
const Touchable = styled.span`
  cursor: pointer;
`;
function Icon({ name, color, theme, width, height, viewBox, onClick }) {
  const IconMapped = map[name] || noop;
  if (onClick) {
    return (
      <Touchable onClick={onClick}>
        <IconMapped
          color={theme[color]}
          width={width}
          height={height}
          viewBox={viewBox}
        />
      </Touchable>
    );
  }
  return (
    <IconMapped
      color={theme[color]}
      width={width}
      height={height}
      viewBox={viewBox}
    />
  );
}

export default withTheme(Icon);
