import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField } from './SearchField';

export function SearchProceduresField({ data, onChange, value, autoFocus }) {
  const { t } = useTranslation();
  return (
    <SearchField
      placeholder={t('Search procedures...')}
      value={value}
      autoFocus={autoFocus}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
