import { useState, useEffect } from 'react';

export function useAsync({ promiseFn, initialValue = null }) {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  function refetch() {
    setLoading(data ? false : true);
    promiseFn()
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
  }
  useEffect(() => {
    refetch();
  }, []);
  return { data, isLoading: loading, error, refetch };
}
