import React from 'react';
import Icon from './Icon';
import styled from 'styled-components';
import color from 'color';
const ProcedurePlayButtonWrapper = styled.div`
  background: ${({ theme }) => color(theme.primary).alpha(0.4).hsl().string()};
  border-radius: 100px;
  padding: 5px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  align-self: center;
`;
export function ProcedurePlayButton({ onClick }) {
  return (
    <ProcedurePlayButtonWrapper onClick={onClick}>
      <Icon name="play" color={'white'} />
    </ProcedurePlayButtonWrapper>
  );
}
