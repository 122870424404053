import React from 'react';
function Share({ width, height, color, viewBox }) {
  return (
    <svg width="22px" height="17px" viewBox="0 0 33 27" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M32,13.5 L17.9090909,1 L17.9090909,9.33333333 C10.0942727,9.33333333 1,12.7583333 1,26 C4.68594588,20.4051514 11.1565408,17.2162381 17.9090909,17.6666667 L17.9090909,26 L32,13.5 Z"
          id="share"
          stroke="#49545A"
          strokeWidth="2"
        ></path>
      </g>
    </svg>
  );
}

export default Share;
