import React from 'react';
import { StaticPage } from '../components/StaticPage';

export const Privacy = () => (
  <StaticPage
    title="Privacy"
    html={`
  <h1>Use of your personal information</h1>
  <p>
  The personal information collected through the user registration will include your name, email, profession, qualification, area of care, NSW public health organisation and health facility.  Your information will be managed in accordance with the NSW Privacy and Personal Information Protection Act 1998.  
  </p>
  <p>
  Your personal information will be held securely by The NSW Agency for Clinical Innovation and MedApps.  Your information will be used to improve user experience within Emergency Procedures, to inform analytic reports to help us understand  Emergency Procedures usage and deliver improved content in the app.   It will also be used to communicate with you about changes to Emergency Procedures.
  </p>
  `}
  />
);
