import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
`;
const Message = styled.span`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: ${({ theme }) => theme.fonts.size.medium};
  color: ${({ theme }) => theme.grey};
  margin-top: 20px;
  padding: 0px 20px;
`;
export function EmptyState({ message, icon }) {
  return (
    <EmptyStateWrapper>
      <Icon name={icon || 'search'} color="grey" width={60} height={60} />
      <Message>{message}</Message>
    </EmptyStateWrapper>
  );
}
