import React from 'react';

function Triangle({ width, height, color }) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M3.84334486,1.65891558 C3.76169438,1.55877496 3.63470822,1.5 3.5,1.5 C3.36529178,1.5 3.23830562,1.55877496 3.15665514,1.65891558 L-1.42683206,7.89012079 C-1.51436964,8.00929094 -1.52408505,8.16397395 -1.4520671,8.29189852 C-1.38004916,8.4198231 -1.23814721,8.49994143 -1.0834872,8.5 L8.0834872,8.5 C8.23814721,8.49994143 8.38004916,8.4198231 8.4520671,8.29189852 C8.52408505,8.16397395 8.51436964,8.00929094 8.42683206,7.89012079 L3.84334486,1.65891558 Z"
          id="triangle"
          fill={color}
          transform="translate(3.500000, 5.000000) rotate(90.000000) translate(-3.500000, -5.000000) "
        ></path>
      </g>
    </svg>
  );
}
Triangle.defaultProps = {
  width: 7,
  height: 10,
};
export default Triangle;
