import React from 'react';

function Back({ color, width, viewBox, height }) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${viewBox}`}
      version="1.1"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="back"
          transform="translate(1.000000, 1.000000)"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <path d="M0.375,11 L30.625,11" id="Path"></path>
          <polyline id="Path" points="11.375 22 0.375 11 11.375 0"></polyline>
        </g>
      </g>
    </svg>
  );
}
Back.defaultProps = {
  width: 25,
  height: 26,
  viewBox: `25 26`,
};
export default Back;
