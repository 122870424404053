import { useReducer, useEffect } from 'react';
import localForage from 'localforage';
import _ from 'lodash';

const createState = () => ({
  procedures: [],
  proceduresMap: {},
});
const noop = (state) => state;
const createReducer = (map) => {
  return (state, action) => {
    const fn = map[action.type] || noop;
    return fn(state, action.payload, action);
  };
};
const initialState = createState();
const reducer = createReducer({
  procedures: (state, payload) => {
    return {
      ...state,
      procedures: payload,
      proceduresMap: payload.reduce(
        (map, procedure) => ({ [procedure.id]: procedure, ...map }),
        {}
      ),
    };
  },
});

const storeState = _.debounce((state) => {
  localForage.setItem('state', state);
}, 1000);
export default function useAppState() {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    (async () => {
      const storedState = await localForage.getItem('state');
      if (!storedState) return;
      dispatch({ type: 'procedures', payload: storedState.procedures });
    })();
  }, []);
  useEffect(() => {
    storeState(state);
  }, [state]);
  return [state, dispatch];
}
