import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Loading } from './Loading';
import { EmptyState } from './EmptyState';
const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
`;
export function ProceduresList({ data, isLoading, error }) {
  const { t } = useTranslation();
  if (isLoading) return <Loading />;
  return (
    <>
      {error && data.length === 0 && <p>Error :(</p>}
      {data && !!data.length && (
        <List>
          {data.map((item) => (
            <ProcedureItem key={item.id} procedure={item} />
          ))}
        </List>
      )}
      {data.length === 0 && <EmptyState message={t(`No Results.`)} />}
    </>
  );
}
const ProcedureWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.black};
  background-color: ${({ theme }) => theme.white};
  text-decoration: none;
  ${({ theme }) => theme.boxShadow};
  &:hover {
    cursor: pointer;
  }
`;
const ProcedureText = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fonts.size.small};
  flex-basis: 99%;
`;
const RightChevron = () => <Icon name="chevron" color="primary" />;
export function ProcedureItem({ procedure }) {
  return (
    <ProcedureWrapper to={`/procedure/${procedure.id}`}>
      <ProcedureText>{procedure.heading}</ProcedureText>
      <RightChevron />
    </ProcedureWrapper>
  );
}
