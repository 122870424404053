import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { Offline } from './Offline';
import { AddToHomeScreen } from './AddToHomeScreen';
import { useSpring, animated } from 'react-spring';
import { Link } from '@reach/router';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
`;

const onClickAdd = () => {
  alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen"`);
};

export function Layout({ header, content, footer }) {
  return (
    <LayoutWrapper className="page">
      <Offline />
      {header}
      {content}
      {footer}
      <AddToHomeScreen onClick={onClickAdd} />
    </LayoutWrapper>
  );
}

Layout.defaultProps = {
  header: null,
  content: null,
};

const PrimaryHeaderWrapper = styled.div`
  padding-top: 20px;
  @supports (padding: max(0px)) {
    padding-top: max(20px, env(safe-area-inset-top));
  }
  padding-bottom: 20px;
  text-align: center;
`;
const Back = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;
const Info = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;
const PrimaryHeaderTitle = styled.div`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fonts.default};
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
`;
const SecondaryHeaderTitle = styled.div`
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.fonts.default};
  font-weight: bold;
  letter-spacing: 1px;
`;
const SecondaryHeaderWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  padding-top: env(safe-area-inset-top);
  background-color: ${({ theme }) => theme.white};
`;
const Top = styled.div``;
const Bottom = styled(animated.div)`
  height: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const HeaderLink = styled(Link)`
  border-top: ${({ theme }) => theme.border};
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  color: ${({ theme, isCurrent }) => (isCurrent ? theme.peach : theme.white)};
  &:hover {
    background-color: ${({ theme }) => theme.primaryHighlighted};
  }
  &:last-child {
    border-bottom: ${({ theme }) => theme.border};
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
`;

export function BackButton({ onClick }) {
  return (
    <Back>
      <Icon onClick={onClick} width={22} height={22} name="back" />
    </Back>
  );
}
export function PrimaryHeader({ title, onClickBack }) {
  const [animatedStyles, set] = useSpring(() => {
    return { height: 0 };
  });
  const [showInfo, setInfo] = useState(false);
  return (
    <PrimaryHeaderWrapper
      style={
        showInfo
          ? {
              paddingBottom: 0,
            }
          : {}
      }
    >
      <Top>
        {onClickBack && <BackButton onClick={onClickBack} />}
        <PrimaryHeaderTitle>{title}</PrimaryHeaderTitle>
        <Info>
          <Icon
            onClick={() => {
              const show = !showInfo;
              setInfo(show);
              set(show ? { height: 99 } : { height: 0 });
            }}
            width={22}
            height={22}
            name="info"
          />
        </Info>
      </Top>
      <Bottom style={animatedStyles}>
        <HeaderLink style={{ marginTop: 20 }} to="/terms-conditions">
          Terms {'&'} Conditions
        </HeaderLink>
        <HeaderLink to="/privacy">Privacy</HeaderLink>
      </Bottom>
    </PrimaryHeaderWrapper>
  );
}
export function SecondaryHeader({ title }) {
  return (
    <SecondaryHeaderWrapper>
      <SecondaryHeaderTitle>{title}</SecondaryHeaderTitle>
    </SecondaryHeaderWrapper>
  );
}

export const WhiteContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  flex-grow: 1;
`;
export const OffWhiteContent = styled.div`
  background-color: ${({ theme }) => theme.offwhite};
  flex-grow: 1;
`;
