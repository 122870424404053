import React, { useState } from 'react';
import { useProceduresSearch } from '../components/useProceduresSearch';
import { useTranslation } from 'react-i18next';
import { Layout, PrimaryHeader, OffWhiteContent } from '../components/Layout';
import { SearchProceduresField } from '../components/SearchProceduresField';
import { ProceduresList } from '../components/ProceduresList';
import { EmptyState } from '../components/EmptyState';

export function Search({ procedures, onProceduresLoaded }) {
  const [searchTerm, updateSearchTerm] = useState('');
  const [filtered, isLoading, error] = useProceduresSearch(
    procedures,
    searchTerm
  );
  const { t } = useTranslation();
  return (
    <Layout
      header={
        <PrimaryHeader
          title={t('Search')}
          onClickBack={() => window.history.back()}
        />
      }
      content={
        <OffWhiteContent>
          <SearchProceduresField
            onChange={updateSearchTerm}
            value={searchTerm}
            autoFocus
          />
          {searchTerm && (
            <ProceduresList
              data={searchTerm ? filtered : []}
              isLoading={isLoading}
              error={error}
            />
          )}
          {!searchTerm && (
            <EmptyState
              message={t(
                `Start typing the name of the procedure you're looking for.`
              )}
            />
          )}
        </OffWhiteContent>
      }
    />
  );
}
