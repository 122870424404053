import React, { useEffect } from 'react';
import { Modal, Prompt } from './Modal';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ShareTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1.2rem;
  color: ${({ theme }) => theme.darkGrey};
  padding: 10px;
`;
const Url = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border};
  padding: 10px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.pink};
`;
const ShareInfo = styled.div`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1rem;
  color: ${({ theme }) => theme.darkGrey};
  padding: 10px;
`;

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}
const CopyButton = styled.div`
  background-color: ${({ theme }) => theme.offwhite};
  padding: 20px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1.2rem;
  color: ${({ theme }) => theme.primary};
`;
function iosCopyToClipboard(el) {
  var oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  var s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  document.execCommand('copy');
  el.blur();
}
export function ShareModal({ show, hide, procedure }) {
  const { t } = useTranslation();
  useEffect(() => {
    copyTextToClipboard(window.location.href);
  }, [window.location.href]);
  const input = React.createRef();
  return (
    <Modal show={show} hide={hide}>
      <Prompt title={`${t('Share')}`}>
        <ShareTitle>{procedure.heading}</ShareTitle>
        <ShareInfo>{t('URL added to clipboard')}</ShareInfo>
        <Url defaultValue={window.location.href} ref={input} />
        <CopyButton
          onClick={() => {
            iosCopyToClipboard(input.current);
            hide();
            alert('Added to clipboard');
          }}
        >
          Copy
        </CopyButton>
      </Prompt>
    </Modal>
  );
}
