import React from 'react';
import { StaticPage } from '../components/StaticPage';

export const TermsAndConditions = ({ navigate }) => (
  <StaticPage
    navigate={navigate}
    title="Terms and Conditions"
    html={`
  
  <h1>Terms and conditions</h1>
  <br clear="ALL"/>
  <p>
      <strong>Copyright</strong>
  </p>
  <p>
      This application known as “Emergency Procedures” and information contained
      in the application (<strong>Application</strong>) is subject to the
      operation of the <em>Copyright Act 1968</em> (Cth), vested in the Crown in
      the right of the State of New South Wales (<strong>ACI</strong>). The
      Application is protected by Crown copyright.
  </p>
  <p>
      The Application may be reproduced in whole or part for study or training
      purposes subject to the inclusion of an acknowledgment of the source as
      follows: © The Agency for Clinical Innovation by and on behalf of the State
      of New South Wales.
  </p>
  <p>
      The Application may not be reproduced for commercial usage or sale. Any
      reproduction for commercial purposes and any purpose other than for study
      or training require the prior written permission from ACI.
  </p>
  <p>
      <strong>Terms and Conditions</strong>
  </p>
  <p>
      By using this Application, as updated by ACI from time to time, including
      any of the content or information it contains, you agree to the following
      terms and conditions.
  </p>
  <p>
      The Application has been developed for medical and nursing staff providing
      care for people in Emergency Departments, to provide general information on
      the best practice delivery of care. The purpose of this Application is to
      provide general information only. It is not a replacement for your own
      independent professional judgement or any relevant local guidelines.
  </p>
  <p>
      <em>General Disclaimer Information</em>
  </p>
  <p>
      The Application contains information, data, documents, pages, videos and
      images (<strong>Information</strong>) prepared by ACI.
  </p>
  <p>
      Whilst the Information contained in this Application has been prepared with
      due care, no representation or warranty (express or implied) is made by ACI
      as to the currency, completeness, accuracy, reliability, suitability,
      and/or availability of the Application or the Information. In addition, no
      representation or warranty (express or implied) is made that the
      Information will meet your particular requirements for health or medical
      care. You should carefully evaluate the currency, completeness, accuracy,
      reliability, suitability and relevance of the available information.
  </p>
  <p>
      You acknowledge that the Information does not indicate an exclusive course
      of action, or serve as a standard of medical care or a substitute for your
      own professional opinion. Variations, taking individual circumstances into
      account, may be appropriate and you must use your own judgment when making
      use of the Application and the Information. We recommend that you verify
      independently the accuracy, reliability and suitability of the Information
      derived from the Application, especially in relation to Information
      relating to drug doses.
  </p>
  <p>
      To the maximum extent permitted by law, ACI expressly disclaims all
      liability (however arising, including for negligence), for any loss,
      damage, injury, cost or expense incurred by you or any other person arising
      by reason of you using, accessing or relying on the Information and whether
      caused by reason of any error, negligent act, omission or misrepresentation
      in the Information or otherwise.
  </p>
  <p>
      The Information may change without notice and ACI is in no way liable for
      the accuracy of any Information printed and stored or in any way
      interpreted and relied on by a user.
  </p>
  <p>
      <em></em>
  </p>
  <p>
      <em></em>
  </p>
  <p>
      <em></em>
  </p>
  <p>
      <em>Links to third-party sites</em>
  </p>
  <p>
      The Information contained in this Application includes information derived
      from various third parties. These are not endorsed or supported by the ACI
      and do not necessarily reflect any policies, procedures, standard or
      guidelines of the ACI.
  </p>
  <p>
      The ACI takes no responsibility for the accuracy, currency, reliability and
      correctness of any information provided by third parties or for the
      accuracy, currency, reliability and correctness of links or references to
      information sources (including Internet Sites) outside of the ACI.
  </p>
  <p>
      Links to other internet sites are not under the control of the ACI and are
      provided for information only. Due to the changing nature of the Internet
      content, it is the responsibility of the users to make their own
      investigations, decisions, enquiries about the information retrieved from
      other Internet sites. Providing these links does not imply any endorsement,
      non-endorsement, support or commercial gain by the ACI.
  </p>
  <p>
      <strong></strong>
  </p>
  <p>
      <strong>Privacy Information</strong>
  </p>
  <p>
      <em>Collection of your personal information</em>
  </p>
  <p>
      By registering and using the Application, you consent to ACI collecting and
      storing personal information relating to registration and use of Emergency
      Procedures for use within the NSW public health system. The personal
      information collected through the user registration will include your name,
      email, profession, qualification, area of care, NSW public health
      organisation and health facility.
  </p>
  <p>
      <em>Use of your personal information</em>
  </p>
  <p>
      <a name="_heading=h.gjdgxs"></a>
      Your personal information will be held securely by ACI and will be used to
      improve user experience within Emergency Procedures, to inform analytic
      reports to help us understand Emergency Procedures traffic and usage and
      deliver improved content in the Application. This information will be
      managed in compliance with the NSW Privacy and Personal Information
      Protection Act 1998. It will also be used to communicate with you about
      changes to the Diabetes Decision Support App.
  </p>
  <p>
      <em>Privacy practices of linked websites</em>
  </p>
  <p>
      Thinksulin contains links to other third party sites. ACI is not
      responsible for the privacy practices of any third parties nor are they
      responsible for the material contained in a website that is linked to this
      site.
  </p>
  <p>
      <em>Changes to this Privacy Policy</em>
  </p>
  <p>
      The ACI reserves the right to make amendments to this Privacy Policy at any
      time.
  </p>
  <p>
      <em>Accessing Your Personal Information</em>
  </p>
  <p>
      You have a right to access your personal information, subject to exceptions
      allowed by law. If you would like to do so, please let us know. You may be
      required to put your request in writing for security reasons.
  </p>
  <p>
      <em>Security</em>
  </p>
  <p>
      The ACI applies a range of security controls to protect from unauthorised
      access. However, users should be aware that as the World Wide Web is an
      insecure public network that gives rise to a potential risk that a user's
      transactions are being viewed, intercepted or modified by third parties or
      that files which the user downloads may contain computer viruses, disabling
      codes, worms or other devices or defects.
  </p>
  <p>
      ACI accepts no liability for any interference with or damage to a user's
      computer system, software or data occurring in connection with or relating
      to this web site or its use. Users are encouraged to take appropriate and
      adequate precautions to ensure that whatever is selected from this site is
      free of viruses or other contamination that may interfere with or damage
      the user's computer system, software or data.
  </p>
  <p>
      <em>Copyright notice</em>
  </p>
  <p>
      © The Agency for Clinical Innovation for and on behalf of the State of New
      South Wales.
  </p>
  <p>
      <em>General copyright statement</em>
  </p>
  <p>
      Copyright in the Information which appears in Thinksulin is subject to the
      operation of the Copyright Act 1968, vested in the Crown in the right of
      the State of New South Wales.
  </p>
  <p>
      <em>Contacting us</em>
  </p>
  <p>
      ACI welcomes your comments regarding this Privacy Policy. If you have any
      questions about this Privacy Policy and would like further information,
      please contact us by any of the following means during business hours
      Monday to Friday.
  </p>
  <p>
      Call: (02) 9464 4666
  </p>
  <p>
      Post: Attn: Privacy Policy,
  </p>
  <p>
      The Agency for Clinical Innovation,
  </p>
  <p>
      PO Box 699
  </p>
  <p>
      Chatswood NSW 2057
  </p>
  <p>
      AUSTRALIA
  </p>
  <p>
      E-mail: aci-info@health.nsw.gov.au
  </p>
`}
  />
);
